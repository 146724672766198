.multiselects {
  --rmsc-main:#ccc;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #FFFFFF;
  --rmsc-border: #ccc;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 10px; /* Spacing */
  --rmsc-radius: 4px; /* Radius */
  --rmsc-h: 38px; /* Height */
  font: normal normal normal 1.4rem "Roboto",sans-serif;
  color: rgb(31,31,31,0.8);
}

.multiselects input[type="checkbox"]{
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  width: 14px;
  height: 14px;
  border: 1px solid #34495E;
  border-radius: 4px;
  outline: none;
  background-color: #FFFFFF;
  cursor: pointer;
}

.multiselects input[type="checkbox"]:checked{
    background-color: #846550;
}

.multiselects input[type="checkbox"]:checked::after{
    content: '\2713';
    text-align: center;
    color: #FFFFFF;
    position: relative;
    left: 0.1rem;
    bottom: 0.27rem;
}
